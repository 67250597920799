.loading-spinner-with-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Adjust to fit your needs */
    width: 100%; /* Adjust to fit your needs */
}

.logo-container {
    position: relative;
    /* Adjust the size of the logo container to fit the spinner size */
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    position: absolute;
    max-width: 50%; /* Adjust the size of the logo */
    max-height: 50%; /* Adjust the size of the logo */
    z-index: 10;
}

/* The spinner will be slightly larger than the logo container to allow for some overlap */
ion-spinner {
    --spinner-color: var(--ion-color-primary); /* This allows for color customization */
    position: absolute;
    width: 120px;
    height: 120px;
}
