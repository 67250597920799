.scene-card {
    background-color: #2e2e2e;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #ffffff;
}

.scene-card-header {
    border-bottom: 1px solid #444;
    margin-bottom: 15px;
}

.scene-title {
    font-size: 1.5em;
    color: #ffffff;
    text-align: center;
}

.scene-card-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.scene-item {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    color: #ffffff;
}

.scene-label {
    font-weight: bold;
    color: #cccccc;
}

.scene-input {
    background-color: #1e1e1e;
    color: #ffffff;
    margin: 5px 5px 5px 0px;
    border-radius: 8px;
}

.scene-toggle {
    font-weight: bold;
    color: #cccccc;
}

ion-toggle {
    --handle-width: 25px;
    --handle-height: 27px;
    --handle-max-height: auto;

    contain: none;
}

ion-toggle::part(track),
ion-toggle.toggle-checked::part(track) {
    height: 10px;
    width: 50px;

    background: #ddd;
    overflow: visible;
}

ion-toggle::part(handle) {
    background: #eb7769;

    border-radius: 4px;
    box-shadow: none;
}

ion-toggle.toggle-checked::part(handle) {
    background: #95c34e;
}

.scene-button {
    --background: linear-gradient(90deg, #5de01c, rgba(53, 188, 91, 0.64));
    color: white;
    border-radius: 30px;
    margin-top: 20px;
}

.cron-set-button {
    --background: green;
}

.existing-cron-job {
    display: flex;
    justify-content: space-between;

    h2 {
        color: #ffffff;
        font-weight: bold;
        padding-left: 0;
    }

    padding-left: 20px;
    margin-top: 5px;
}
