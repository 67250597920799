.transaction-modal {
    --height: 50%;
    --border-radius: 30px;
}

.modal-content {
    position: relative;
    padding: 20px;
    background-color: #1f1f1f;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #0044ff, #022c9e);
    border-radius: 20px 20px 0 0;
    padding: 30px;
    margin-bottom: 20px;
}

.avatar {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.large-icon {
    color: #fff;
    font-size: xxx-large;
}

.submit-button {
      margin-top: 20px;
      --background: #00d977;
      font-size: 1.2rem;
      font-weight: bold;
      border-radius: 25px;
      --color: #fff;
  }

.custom-input-item {
    --background: #121212;
}

