ion-toast.toast-top-right {
    --start: 120px;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
}


.sidebar-toolbar {
    display: flex;
    flex-direction: row;
    font-family: "Roboto Light", sans-serif;
    color: white;
    max-height: 30px;
    margin-right: 15px;
}

.sidebar-content {
    font-family: "Roboto Light", sans-serif;
}

.sidebar-content ion-icon {
    color: #fff;
}

.sidebar-content ion-item {
    margin: 5px 5px;
    /*padding-left: 10px;*/
    border-radius: 10px;
}


.sidebar-content .not-selected ion-item:hover {
    --background: rgba(255, 255, 255, 0.2);
}

.sidebar-content .selected ion-item:hover {
    --background: #428cff;
}

.selected {
    --background: #428cff;
}

/* For ion-icon inside selected item */
.selected ion-icon {
    color: white;
}
