ion-toast.toast-top-right {
    --start: 120px;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
}


.page-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: sticky;
    top: 0px;
    padding: 10px 0px;
    z-index: 1;
    background-color: transparent;
}

ion-segment-button.segment-selected {
    text-decoration: none;
}

.presence-card-title {
    display: flex;
    justify-content: space-between;
}

.route-evenings-button {
    --background: #3b3737;
}

.personal-stats-content {
    --background: rgba(0, 0, 0, 0.5);
}

ion-card {
    border-radius: 25px;
}

ion-card-header {
    color: white;
}

ion-card-content {
    color: white;
}

.custom-grid {
    display: flex;
    flex-wrap: wrap;
}

.custom-card {
    background: linear-gradient(to bottom right, rgb(59, 55, 55) 0%, rgba(59, 57, 57, 0.87) 50%, rgb(59, 55, 55) 100%);
    border-radius: 8px;
    margin: 8px;
    flex-grow: 1;
}

.custom-card-header {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
}

.custom-card-content {
    display: flex;
    justify-content: center;
}

.percentage-component {
    height: 70px;
    width: 100px;
}

.custom-percentage-card-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.pay-for-balance-button {
    width: 50%;
}

#beer {
    transition: all 3s ease;
}

svg:hover #beer {
    transform: translateY(100%);
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-spinner {
    --ion-spinner-color: #007bff; /* Adjust the color as needed */
    --ion-spinner-size: 12rem; /* Adjust the size as needed */
}


ion-spinner {
    width: 100px;
    height: 100px;
}

.spinner-grid {
    display: flex;
    justify-content: center;
}

.centered-profile-pic {
    display: flex;
    justify-content: center;
}

.refresh-label {
    display: flex;
    justify-content: flex-end;
    opacity: 0.5;
}

.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
