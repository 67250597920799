.card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.description-date {
    display: flex;
    flex-direction: column;
}

.amount {
    font-size: 1.2em; /* Adjust as necessary */
}

ion-card-title {
    font-size: 1.5em; /* Adjust as necessary */
}

ion-card-subtitle {
    font-size: 1em; /* Adjust as necessary */
}

.description-title {
    color: white;
}

.date-title {
    padding-top: 16px;
    padding-left: 25px;
    color: darkgray;
}

.transaction-icon {
    width: 20px;
    height: 20px;
    color: black;
    border-radius: 50px;
    background: linear-gradient(to bottom right, rgb(255, 161, 56) 0%, rgba(100, 75, 32, 0.87) 50%, rgb(255, 154, 41) 100%);
}

.checkin-icon {
    width: 20px;
    height: 20px;
    color: black;
    border-radius: 50px;
    background: linear-gradient(to bottom right, rgb(8, 147, 36) 0%, rgba(74, 63, 182, 0.87) 50%, rgb(136, 70, 162) 100%);
}

.left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


.column-container {
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.full-amount {
    font-size: 1.2em; /* Adjust size as needed */
}

.decimal-amount {
    font-size: 0.8em; /* Adjust size as needed */
    vertical-align: super;
}
