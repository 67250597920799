.popover-layout {
    --width: 90%;
    --max-width: 500px;
    border-radius: 20px;
    padding: 20px;
    margin: 10px;
    justify-content: center;
}

.add-stripes-grid {
    display: flex;
    flex-direction: column;
}

.custom-row {
    display: flex;
    margin: 8px 0;
}

.custom-col {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 0 4px;
}

.custom-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

ion-checkbox {
    margin: 8px;
}

.action-sheet-button {
    color: white !important;
    font-weight: bold;
}


.action-sheet-selected {
    background-color: #E1F5FE;
}

.action-sheet-cancel {
    color: #F44336 !important;
}

.action-sheet-button-inner {
    font-size: 16px;
}
