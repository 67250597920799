.ion-card-title {
    font-size: 25px;
}

.last-move-card-title {
    font-size: 25px;
    color: white;
}

.img-card {
    background-color: transparent;
}

.dertigen-label {
    display: flex;
    justify-content: space-around;
}

.dertigen-card {
    --background: linear-gradient(to bottom right, rgb(8, 38, 217) 0%, rgba(1, 128, 180, 0.87) 50%, rgb(41, 134, 241) 100%);
    border-radius: 50px;
    position: relative;
}

.dertigen-card-illegal {
    --background: linear-gradient(to bottom right, rgb(217, 8, 8) 0%, rgba(180, 1, 70, 0.87) 50%, rgb(112, 7, 12) 100%);
    border-radius: 50px;
    position: relative;
}

.dertigen-card-buyed-out {
    --background: linear-gradient(to bottom right, rgb(122, 115, 115) 0%, rgba(59, 57, 57, 0.87) 50%, rgb(103, 97, 97) 100%);
    border-radius: 50px;
    position: relative;
    opacity: 50%;
}

.last-move-card {
    --background: linear-gradient(to bottom right, rgb(123, 8, 217) 0%, rgba(114, 1, 180, 0.87) 50%, rgb(184, 41, 241) 100%);
    border-radius: 50px;
    /*width: 100%;*/
    position: relative;
}

.buy-in-card {
    --background: linear-gradient(to bottom right, rgb(128, 123, 123) 0%, rgba(84, 86, 91, 0.87) 50%, rgb(51, 52, 56) 100%);
    border-radius: 50px;
}

.black-text {
    position: absolute;
    color: black;
    right: 0;
    display: none;
}

.play-direction-label {
    text-align: center;
}

.dice-container-div {
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    padding: 10px 0;
    z-index: 1;
}

.floating-button-container {
    position: sticky;
    bottom: 0;
}

.split-screen {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.move-input-container {
    background: linear-gradient(to bottom right, rgb(231, 149, 86) 0%, rgb(208, 151, 109) 50%, rgb(171, 108, 58) 100%);
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
}

.seperator {
    border-bottom: 4px solid rgba(255, 255, 255, 0.87);
    margin: 25px 0;
    width: 100%;
}

.reorder-button-container {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.toggle-reorder-btn {
    --background: rgb(231, 149, 86);
    font-size: large;
    font-weight: bolder;
}

.popover-content {
    padding: 15px;
    text-align: center;
}

.popover-title {
    font-size: 20px;
    margin-bottom: 10px;
}

.popover-hint {
    font-size: 14px;
    color: #888;
    margin-top: 10px;
}
