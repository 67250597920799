.currency-input-item {
    --inner-padding-end: 0px;
    --inner-padding-start: 0px;
    padding: 16px;
    background: #121212;
    border: none;
    margin-top: 10px;
}

.currency-input {
    font-size: 2rem;
    text-align: left;
    color: white;
    background: none;
    border: none;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.currency-input::placeholder {
    color: #aaa;
}
