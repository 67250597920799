.ion-card-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    margin: 0;
    padding: 0;
    text-align: center;
}

.p-styling {
    font-size: 1rem;
    color: white;
    margin: 0;
    padding: 0;
    text-align: center;
}
