.present-card-overview {
    border-radius: 50px;
    --background: linear-gradient(to bottom right, rgb(128, 123, 123) 0%, rgba(84, 86, 91, 0.87) 50%, rgb(51, 52, 56) 100%);
    display: flex;
    justify-content: center;
}

.flex-container-presence {
    display: flex;
    flex-wrap: wrap;
    background: bottom;
    justify-content: flex-start;
}

.flex-item-presence {
    flex-basis: calc(33% - 10px); /* Example for two cards per row. Adjust as needed. */
    margin: 5px; /* Half of the gap you want between cards. */
}

.presence-name-header {
    font-size: x-large;
    /*font-weight: bold;*/
    color: white;
}

.presence-date-title {
    color: white;
    font-weight: bold;
}
