
.admin-list-item {
    --background: linear-gradient(to bottom right, rgb(241, 7, 28) 0%, rgba(180, 1, 82, 0.87) 50%, rgb(107, 11, 11) 100%);
    border-radius: 30px;
    padding: 5px;
    align-items: center;
}

.admin-label {
    display: flex;
    justify-content: space-around;
}

.admin-list {
    display: flex;
    flex-direction: column;
}

.fridge-controller-container {
    position: sticky;
    bottom: 0;
}

.sticky-checkboxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #1e2023;
    padding: 10px;
    z-index: 100;
    max-width: 100%;
    border-radius: 5px;
}

.role-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.role-container ion-checkbox {
    --size: 16px;
    --checkbox-background-checked:  linear-gradient(to bottom right, rgb(8, 38, 217) 0%, rgba(1, 128, 180, 0.87) 50%, rgb(41, 134, 241) 100%);;
}

.role-container ion-checkbox::part(container) {
    border-radius: 6px;
    border: 2px solid black;
}

.role-container ion-label {
    margin-right: 10px;
}

/* Grid layout for list */
.admin-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* two items per row */
    grid-gap: 5px; /* space between grid items */
}

.role-user {
    --background: linear-gradient(to bottom right, rgb(241, 7, 28) 0%, rgba(180, 1, 82, 0.87) 50%, rgb(107, 11, 11) 100%);
}

.role-regular-guest {
    --background: linear-gradient(to bottom right, rgb(12, 145, 145) 0%, rgba(5, 80, 93, 0.87) 50%, rgb(10, 45, 138) 100%);
}

.role-dertigen-user {
    --background: linear-gradient(to bottom right, rgb(145, 92, 12) 0%, rgba(239, 138, 95, 0.87) 50%, rgb(250, 59, 59) 100%);
}

.total-balance-container {
    padding: 20px;
    text-align: center;
}

.total-balance-container .IonCard {
    background-color: #f0f0f0; /* Example background color */
    color: #333; /* Example text color */
}
