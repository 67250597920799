.large-medal {
    font-size: 20px;
}

.loading-spinner-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
