.stripes-button-box {
    display: flex;
    justify-content: center;
}

ion-button.add-stripes-button {
    --background: linear-gradient(to bottom right, rgb(217, 8, 214) 0%, rgba(134, 23, 201, 0.87) 50%, rgb(117, 8, 147) 100%);
}

ion-button.normal {
    --background: linear-gradient(to bottom right, rgb(8, 38, 217) 0%, rgba(1, 128, 180, 0.87) 50%, rgb(41, 134, 241) 100%);
}

ion-button.green {
    --background: linear-gradient(to bottom right, rgb(109, 217, 8) 0%, rgba(82, 201, 23, 0.87) 50%, rgb(113, 231, 94) 100%);
}

ion-select {
    --placeholder-color: white;
    --placeholder-opacity: 1;
    width: 100%;
    justify-content: center;
}

ion-select::part(placeholder),
ion-select::part(text) {
    flex: 0 0 auto;
}

ion-select::part(text),
ion-select::part(icon),
ion-select::part(dialog) {
    color: white;
}

ion-select::part(dialog) {
    --color: white;
}


ion-select-option {
    --color: white;
}
.ion-select-option-class::part(button) {
    color: white;
}
