.statute-card {
    background: white;
    color: black;
}

.statute-card-content {
    color: black;
}

hr {
    background: black;
    width: 90%;
}

.content-description {
    margin-left: 20px;
    margin-right: 10px;
}

.rule-description {
    margin-left: 25px;
    margin-right: 10px;
}

.statute-title {
    color: black;
    font-weight: bold;
}
