.lobby-page {
    --ion-color-primary: linear-gradient(to bottom right, rgb(15, 46, 224) 0%, rgba(1, 128, 180, 0.87) 50%, rgb(41, 98, 241) 100%);
}

.bottom-button-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.bottom-button {
    position: absolute;
    bottom: 40px;
}

.custom-checkbox {
    --border-color: #dcdcdc;
    --border-style: solid;
    --border-width: 1px;
    --border-radius: 4px;
    --checkmark-color: #0000ff; /* The color of the checkmark */
    --transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.custom-checkbox-checked {
    --background-checked: #0000ff; /* The color you want when checked */
    --border-color-checked: #0000ff;
}

.custom-checkbox ion-checkbox {
    margin-right: 10px; /* Adjust the spacing to your liking */
}

.custom-checkbox-label {
    font-size: 16px; /* Adjust font size to match your design */
}




