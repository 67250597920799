.stripes-button-box {
    display: flex;
    justify-content: center;
}

.special-button-box {
    display: flex;
    justify-content: space-around;
}

ion-button.stripes-to-self {
    --background: rgb(189, 43, 72);
}

ion-button.others {
    --background: rgb(45, 95, 241);
}

.input-for-self {
    border: 4px solid rgb(189, 43, 72);
    border-radius: 5px;
}

.input-for-next {
    border: 4px solid rgb(45, 95, 241);
    border-radius: 5px;
}

.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
