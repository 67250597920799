/* ----------------------------
   CSS Variables & Base Styles
----------------------------- */
:root {
    --color-bg-dark: rgba(31, 30, 30, 0.9);
    --color-dark-gray: rgba(31, 30, 30, 0.64);
    --color-purple-glow: rgba(128, 0, 128, 0.3);
    --color-blue-glow: rgba(0, 44, 213, 0.3);

    --gradient-stats: linear-gradient(45deg, #7b2cbf, #9d4edd, #c77dff);
    --gradient-visuals: linear-gradient(45deg, #3d64fa, #1fa1f8, #021c88);

    --border-radius: 12px;
    --profile-size: 80px;
    --profile-border-width: 4px;

    --transition-duration: 0.3s;
    --box-shadow-sm: 0 4px 15px;
}

/* Optional: Subtle Glow Animation */
@keyframes border-glow {
    0% {
        filter: brightness(1);
    }
    50% {
        filter: brightness(1.3);
    }
    100% {
        filter: brightness(1);
    }
}

/* ----------------------------
   STORIES (Top Section)
----------------------------- */
.stories-container {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    white-space: nowrap;
    gap: 15px;
}

.story {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-picture {
    width: var(--profile-size);
    height: var(--profile-size);
    border-radius: 50%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--profile-border-width) solid transparent;
}

.profile-picture img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profile-picture.negative {
    border-color: red;
}

.profile-picture.positive {
    border-color: green;
}

.username {
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
}

/* ----------------------------
   DASHBOARD LAYOUT
----------------------------- */
.dash-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    height: 85%;
    min-height: 0;
}

.row-stats-feed, .row-chart {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
    margin: 10px;
    flex-grow: 1;
}

/* ----------------------------
   CHARTS
----------------------------- */
.chart {
    background: var(--color-dark-gray);
    border-radius: var(--border-radius);
    padding: 1%;
    border: 1px solid darkgray;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    height: 30vh;
    max-height: 50vh;
}

@media (min-height: 800px) {
    .chart {
        height: 35vh;
    }
}

@media (min-height: 1000px) {
    .chart {
        height: 40vh;
    }
}

/* ----------------------------
   STATS & FEED CONTAINERS
----------------------------- */
.stats-container,
.chart {
    flex: 0 0 calc((100% - 15px) / 2);
}

.stats-container {
    position: relative;
    border-radius: var(--border-radius);
    min-height: 300px;
    max-height: 400px;
    padding: 1%;
    display: flex;
    justify-content: center;
}

.stats-container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: var(--border-radius);
    padding: 3px;
    background: var(--gradient-stats);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    animation: border-glow 3s infinite alternate ease-in-out;
}

.feed-container {
    background: var(--color-bg-dark);
    overflow: hidden;
    box-shadow: var(--box-shadow-sm) var(--color-purple-glow);
}

.feed-column {
    height: 100%;
    width: 100%;
    border-radius: 18px;
    overflow-y: hidden;
}

.event-card {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    border-radius: 50px;
    color: black;
    --background: white;
}

.event-card h2 {
    font-weight: bolder;
}

.no-events {
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

/* ----------------------------
   VISUALS
----------------------------- */
.visuals {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px;
    width: 100%;
    background: var(--color-bg-dark);
    overflow: hidden;
    box-shadow: var(--box-shadow-sm) var(--color-blue-glow);

}

.visuals::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: var(--border-radius);
    padding: 3px;
    background: var(--gradient-visuals);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

.visual-card {
    flex: 0 0 calc((100% - 100px) / 3);
    background: linear-gradient(135deg, #2773ff, #4f4fff);
    border-radius: var(--border-radius);
    text-align: center;
    transition: transform var(--transition-duration) ease, box-shadow var(--transition-duration) ease;
}

.visual-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px var(--color-purple-glow);
}

.qr-card {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stat-card h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
}

.stat-header {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.stat-subtext {
    font-size: 14px;
    color: #bbbbbb;
    margin-top: 5px;
}

/* ----------------------------
   RESPONSIVE MEDIA QUERIES
----------------------------- */
@media (max-width: 900px) {
    .row-stats-feed {
        flex-direction: column;
        align-items: center;
    }

    .stats-container {
        width: 100%;
        min-height: 250px;
        max-height: none;
    }

    /* Hide internal .visuals if needed on smaller screens */
    .stats-container .visuals {
        display: none;
    }

    .row-chart {
        flex-direction: column;
        align-items: center;
    }

    .chart {
        width: 90%;
        min-height: 250px;
    }

    .visuals {
        flex-direction: column;
        align-items: center;
    }

    .visual-card {
        width: 90%;
        max-width: none;
    }
}

.no-guests {
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
}
