.dice-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px; /* Adjust the gap between dice */
    max-width: 400px; /* Adjust the maximum width as needed */
    margin: 0 auto;
    justify-content: center;
}

.dice {
    width: 100%; /* Dice takes up the full column width */
    box-sizing: border-box;
}

/* Add styles for the frozen dice, if needed */
.frozen {
    /* Your frozen styles here */
}

button {
    display: block;
    margin: 20px auto; /* Adjust the button margin as needed */
}

.dice-center {
    display: flex;
    justify-content: center;
}

.throw-button {
    --background: linear-gradient(to bottom right, rgb(217, 8, 214) 0%, rgba(134, 23, 201, 0.87) 50%, rgb(117, 8, 147) 100%);
}
