.user-card-header {
    color: white;
    font-weight: bold;
}

.user-card {
    position: sticky;
    top: 0px;
    padding: 10px 0px;
    z-index: 1;
}

ion-modal ion-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Make the "Check in" button take up the whole width */
ion-modal ion-button {
    display: block;
    width: 100%;
}

.user-info-container {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}


.user-container {
    display: flex;
    flex-direction: row;
}
